export const SERVICE_API_SUCCESS = "SERVICE_API_SUCCESS";
export const SERVICE_API_LOADING = "SERVICE_API_LOADING";
export const SERVICE_API_ERROR = "SERVICE_API_ERROR";


// get all services 
export const SERVICES_GET_API_LOADING = "SERVICES_GET_API_LOADING";
export const SERVICES_GET_API_SUCCESS = "SERVICES_GET_API_SUCCESS";
export const SERVICES_GET_API_ERROR = "SERVICES_GET_API_ERROR";

// Delete the service  
export const SERVICES_DELETE_API_LOADING = "SERVICES_DELETE_API_LOADING";
export const SERVICES_DELETE_API_SUCCESS = "SERVICES_DELETE_API_SUCCESS";
export const SERVICES_DELETE_API_ERROR = "SERVICES_DELETE_API_ERROR";
